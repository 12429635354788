import { template as template_63c50881b47b4969b9b9e73ee27633ee } from "@ember/template-compiler";
const SidebarSectionMessage = template_63c50881b47b4969b9b9e73ee27633ee(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
