import { template as template_02234a6a02b24e6fb54ec22bba49e3c9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_02234a6a02b24e6fb54ec22bba49e3c9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
