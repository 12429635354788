import { template as template_53e1194650a7418bb6149f2151e1770a } from "@ember/template-compiler";
const FKText = template_53e1194650a7418bb6149f2151e1770a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
